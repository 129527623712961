<template>
	<a-spin :spinning="spinning" tip="上传中,可以刷新去别的页面操作">

		<div class="app-box">
			<div class="title-box">
				<div class="line"></div>
				<p class="title">设备导入</p>
			</div>
			<a-divider style="margin-left: -10px" />
			<a-form-model ref="ruleForm" :model="form" layout="inline" labelAlign="left">
				<a-form-model-item>
					<a-upload accept=".xlsx,.xls,.cvn" :multiple="false" :beforeUpload="beforeUpload"
						:showUploadList="false" :headers="{
							Authorization: 'Bearer ' + token
						}" @change="handleChange" :action="$domain + '/admin/v1/device/importDevice'">
						<a-button>
							<a-icon type="upload" /> 上传导入设备
						</a-button>
					</a-upload>
				</a-form-model-item>
				<a-form-model-item>
					<a :href="$domain + '/static/excel/deviceImport.xlsx'">
						【下载导入模板】&nbsp;|
					</a>
					<a @click="exportName()">
						【下载分类模板】
					</a>
					&nbsp;&nbsp;&nbsp;
					<a style="color:crimson" disabled>下载导入模板后请按照示例数据进行填写,示例数据只供参考,请勿上传!</a>
				</a-form-model-item>
			</a-form-model>


			<a-table class="main-table" :pagination="{
				current: query.page,
				total: pagetotal
			}" @change="listChange" :columns="columns" :data-source="data" rowKey="id" style="margin-top: 14px" v-viewer>
				<span slot="is_suc" slot-scope="text, record">
					{{ record.is_suc == 1 ? '导入成功' : (text == -1 ? '导入失败' : '处理中') }}
				</span>
			</a-table>
		</div>
	</a-spin>
</template>

<script>
import {
	domain
} from '@/siteInfo.js'
import {
	get
} from 'js-cookie'
import {
	type
} from 'os'

export default {
	data() {
		return {
			form: {},
			domain: domain,
			query: {
				page: 1,
				limit: 10
			},
			file: null,
			uping: false,
			data: {},
			pagetotal: 0,
			spinning: false,
			columns: [{
				title: '序号',
				customRender: (text, record, index) => index + 1
			},
			{
				title: '导入excel名',
				dataIndex: 'excel_name',
			},
			{
				title: '导入时间',
				dataIndex: 'create_at_str'
			},
			{
				title: '操作人',
				dataIndex: 'do_name'
			},
			{
				title: '导入状态',
				dataIndex: 'is_suc',
				scopedSlots: {
					customRender: 'is_suc'
				}
			},
			{
				title: '导入总数',
				dataIndex: 'count'
			},
			{
				title: '成功个数',
				dataIndex: 'suc_count'
			},
			{
				title: '更新个数',
				dataIndex: 'jump_count'
			},
			{
				title: '跳过个数',
				dataIndex: 'up_count'
			},
			{
				title: '失败原因',
				dataIndex: 'reason'
			},
			],
		}
	},
	methods: {
		listChange(e) {
			this.query.page = e.current
			this.getList();
		},
		getList() {
			this.$post(domain + '/admin/v1/device/importLogList', this.query).then(res => {
				if (res.code === 0) {
					this.data = res.data.list
					this.pagetotal = res.data.total
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		beforeUpload(file) {
			this.file = file;
			this.uping = true;
			this.spinning = true;
			console.log('before', file);
		},
		handleChange(e) {
			console.log('改变。。。');
			if (e.file.status == 'done') {
				console.log(e.file.response);
				this.spinning = false
				this.uping = false
				let { msg, data, code } = e.file.response;
				if (code == 0) {
					this.$message.success(msg, 1.5);
					this.getList()
				} else {
					this.$message.error(msg, 1.5);
				}
				console.log(data);
			}
		},
		exportName() {
			window.open(domain + '/admin/v1/dictionary/exportName?token=' + this.$store.state.token);
		}

	},
	created() {
		this.getList()
	},
	computed: {
		token() {
			return this.$store.state.token;
		}
	},
}
</script>

<style lang="less" scoped>
.img-box {
	overflow: hidden;
	display: inline-block;

	.img-icon {
		transform: translateX(-100px);
		filter: drop-shadow(blue 100px 0)
	}
}

.title-box {
	display: flex;
	align-items: center;
	margin-left: 8px;

	.line {
		width: 5px;
		height: 14px;
		background-color: #4E80F8;
	}

	.title {
		color: #383F50;
		font-size: 16px;
		margin-left: 5px;
	}
}

.img-qr {
	height: 30px;
	cursor: pointer;
}
</style>

<style lang="less">
.form-search {
	.ant-form-item-label {
		width: 70px;
	}
}
</style>
